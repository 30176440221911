<template>
  <footer id="footer" class="section-footer">
    <div class="container-custom footer">
      <div class="footer__content">
        <div class="footer__contact">
          <nuxt-link :to="PAGE.HOME" class="footer__info--logo">
            <BaseImg src="/assets/images/logo.svg" alt="logo" />
          </nuxt-link>
          <div class="footer-contact">
            <div class="footer-contact--row">
              <a
                v-for="(contact, index) in contacts"
                :key="index"
                class="footer-contact--item"
                :href="contact.url"
                target="_blank"
                @click="handleClick(contact)"
              >
                <base-img :src="contact.img" :alt="contact.display_name" class="icon" width="24" height="24" />
                <a>{{ contact.content }}</a>
              </a>
            </div>
          </div>
        </div>
        <div class="footer__info">
          <div class="footer__contact--title">Về chúng tôi</div>
          <div class="footer__info--desc">
            <div
              v-for="item in INTRODUCE_LIST"
              :id="`desc-${item?.name}`"
              :key="item.name"
              class="desc__item"
              :class="item?.type"
              :to="item.url"
            >
              <template v-if="item.hide !== '0'">
                <NuxtLink :to="item.url" class="desc__item--link">
                  <CommonBaseImg class="icon" :src="item.icon" :alt="item.name" />
                  <p class="text">{{ item.name }}</p>
                </NuxtLink>
              </template>
            </div>
          </div>
        </div>
        <div class="footer__method">
          <div class="footer__method--title">Phương thức thanh toán</div>
          <div class="footer__method--list">
            <nuxt-link
              v-for="(method, index) in FOOTER_METHOD()"
              :key="index"
              class="item"
              @click.prevent="handleClickMethod(method.link)"
            >
              <BaseImg :src="method.image" alt="icon" />
              <span>{{ method.title }}</span>
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="footer__providers">
        <div class="footer__providers--list">
          <div v-for="(providerLogo, index) in FOOTER__PROVIDERS" :key="index" class="provider">
            <BaseImg :src="providerLogo" alt="logo-provider" />
          </div>
          <div v-for="(providerLogo, index) in FOOTER__PROVIDERS" :key="`duplicate-${index}`" class="provider">
            <BaseImg :src="providerLogo" alt="logo-provider" />
          </div>
        </div>
      </div>
    </div>
    <div class="section-footer__bottom">
      <div class="section-footer__bottom--wrapper container-custom">
        <div class="copyright">
          <BaseImg src="assets/images/dmca-mobile.webp" class="dmca-img" alt="DMCA.com Protection Status" />
          Copyright © 2024 Powered By {{ $config.BRANCH_NAME }}.com All Rights Reserved.
        </div>
        <div class="navigation-link">
          <BaseImg src="/assets/images/copyright-logo.webp" class="copyright-img" alt="copy right" />
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import useModal from '~/composables/useModal'
import { PAGE_URLS } from '~/config/page-url'
import { MODAL_TYPES } from '~/config/constant'
import { FOOTER_NAVIGATION, FOOTER_METHOD, FOOTER__PROVIDERS } from '~/constants/footer'
import { PAGE } from '~/constants/router'
import { getFooterAboutUs } from '@/constants/footer'

const { INTRODUCE_LIST } = getFooterAboutUs()

const { fetchBranchContact, contacts } = useContact()

const { $config, $pinia, $openLiveChat } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser } = storeToRefs(store)
const { openModalWithNavigate } = useModal()
const router = useRouter()

onMounted(async () => {
  await fetchBranchContact()
})

const handleClickMethod = (link: string) => {
  if (currentUser.value === null) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', link)
  } else {
    navigateTo(link)
  }
}

const openLink = (url: string) => {
  switch (url) {
    case 'LIVECHAT':
      // TODO
      break
    case 'TELEGRAM_CSKH':
      window.open(useRuntimeConfig().TELEGRAM_CSKH, '_blank')
      break
  }
}

const handleClick = (contact: any) => {
  if (contact.name.lowerCase() === 'livechat') {
    const openNewTab = window.open('about:blank', '_blank')
    if (openNewTab !== null) {
      openNewTab.location.href = contact?.url
    }
  }
}

const goHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/footer/index.scss" />
