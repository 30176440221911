<template>
  <div id="header" class="header">
    <div class="header__container container-custom">
      <div class="header-menu">
        <nuxt-link id="logo" class="logo" :to="PAGE_URLS.HOME">
          <BaseImg :src="LOGO_SITE_IMAGE" alt="logo" />
        </nuxt-link>
        <client-only>
          <div class="menu">
            <nuxt-link
              v-for="(item, index) in menuList"
              :id="`link-${item?.name}`"
              :key="index"
              class="menu__item"
              :class="[
                item?.name,
                {
                  openSubMenu: openSubMenu === item?.name,
                  active:
                    (item?.name === route?.name &&
                      !(item?.name === 'game-type' && route?.params?.type === 'quay-so')) ||
                    item?.type === route?.name ||
                    item?.activeUrl === route.fullPath
                }
              ]"
              :to="item.activeUrl"
              @mouseover="onMouseOver(item)"
              @mouseleave="onMouseLeave()"
            >
              <span class="menu-title">{{ item?.title }}</span>
              <span v-if="item.subMenu && item.subMenu?.length" class="icon-menu-down" />
              <div v-if="item.subMenu && item.subMenu?.length" class="sub-menu" :class="item?.name">
                <div class="sub-menu__left">
                  <div class="recommend-game__name sub-menu__left--title">{{ item?.subTitle }}</div>
                  <div class="sub-menu__container" :class="{ gridmenu: item.subMenu?.length > 6 }">
                    <nuxt-link
                      v-for="(subItem, subIndex) in item.subMenu"
                      :id="`sub-header-${subIndex}`"
                      :key="`${index}${subIndex}`"
                      class="sub-menu__item"
                      :class="[subItem?.type, subItem.alias, { active: subItem.url === route.fullPath }]"
                      :to="
                        item?.type === 'sports'
                          ? subItem?.alias
                            ? PAGE_URLS.SPORT + '/' + subItem?.alias
                            : PAGE_URLS.SPORT
                          : item?.rootActiveUrl + `/${subItem?.alias}`
                      "
                    >
                      <div class="sub-menu__item--content">
                        <BaseImg class="icon-active" :src="subItem?.icon" :alt="subItem?.display_name" />
                        <div class="text">
                          <p>{{ subItem?.display_name }}</p>
                        </div>
                      </div>
                      <div v-if="!subItem?.isNotShowLine" class="line" />
                    </nuxt-link>
                  </div>
                </div>
                <div class="line-recommend" />
                <div class="recommend-game">
                  <div class="recommend-game__name">{{ item?.titleRecommend }}</div>
                  <nuxt-link :to="item.type === 'sports' ? item?.itemRecommend?.linkRecommend : ''">
                    <BaseImg
                      class="recommend-game__icon"
                      :src="item.itemRecommend?.img"
                      alt="icon-recommend"
                      @click.prevent="onClickGame(item.itemRecommend, item.type)"
                    />
                  </nuxt-link>
                </div>
              </div>
            </nuxt-link>
          </div>
        </client-only>
      </div>
      <div class="header-button">
        <BaseImg
          id="search"
          class="header-button__search"
          src="/assets/images/components/desktop/icon-search.svg"
          alt="search"
          @click="openModalOne(MODAL.SEARCH)"
        />
        <div id="notification-header" class="header-button__notif">
          <BaseImg
            id="bell"
            class="header-button__notif--bell"
            src="/assets/images/components/desktop/icon-notif.svg"
            alt="bell"
          />
          <NotificationList />
        </div>
        <client-only>
          <ModalSearch />
          <UserNotLogin v-if="!currentUser" />
          <UserLogged v-else />
        </client-only>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import debounce from 'lodash/debounce'
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import useModal from '~/composables/useModal'
import { MODAL } from '~/constants/modal'
import BaseImg from '~/components/common/base-img.vue'
import { useAppStore } from '@/store/app'
import { getMenuHeader } from '~/constants/menu'
import ModalSearch from '~/components/desktop/modals/search'
import UserNotLogin from '~/components/desktop/header/user-not-login.vue'
import UserLogged from '~/components/desktop/header/user-logged.vue'
import AnimateNumber from '~/components/common/animate-number.vue'
import NotificationList from '~/components/desktop/header/notification-list.vue'
import { IMenuHeader, ISubMenuHeader } from '~/types/menu.type'
import { IItemCongGame } from '~/types/lobby.type'
import { useJackpot } from '~/composables/game/useJackpot'
import { useGameStore } from '~~/store/game'
import { LOBBY_GAME_URLS, LOBBY_CASINO_URLS, PAGE_URLS } from '~/config/page-url'
import { IProvider } from '~/types/lobby.type'
import { PATH_ICON } from '~/constants/menu'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot, currentUser } = storeToRefs(store)
const storeGame = useGameStore($pinia)
const { providerGames, providerCasino } = storeToRefs(storeGame)
const openSubMenu = ref('')
const { MENUS } = getMenuHeader()
const { fetchSumJackpot } = useJackpot()
const { fetchCongGame, fetchSportMenu, listSportMenu } = useGame()
const { fetchGameCasino } = useCasino()
const route = useRoute()
const router = useRouter()
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const { openModalOne } = useModal()

const onMouseOver = (item: IMenuHeader) => {
  if (item.subMenu && item.subMenu.length) {
    if (item?.name != null) {
      openSubMenu.value = item.name
    }
  }
}
const onMouseLeave = () => {
  openSubMenu.value = ''
}

const fetchData = () => {
  if (providerGames.value?.length === 0) {
    fetchCongGame()
  }
  if (providerCasino.value?.length === 0) {
    fetchGameCasino()
  }
}

onMounted(() => {
  fetchData()
  fetchSportMenu()
})

const menuList = reactive(MENUS)

const getSumJackpot = (aliasMenu: string) => {
  switch (aliasMenu) {
    case 'no-hu': {
      return sumJackpot.value.jackpotNohu
    }
    case 'ban-ca': {
      return sumJackpot.value.jackpotFishing
    }
    case 'table-game': {
      return sumJackpot.value.jackpotFishing
    }
    default: {
      return 0
    }
  }
}

watchEffect(() => {
  const updateSubMenu = (submenu: ISubMenuHeader[], type: string) => {
    const targetItem = menuList.find((item) => item.type === type)
    if (targetItem && submenu?.length) {
      targetItem.subMenu = submenu.map((item) => {
        return {
          ...item,
          jackpot: getSumJackpot(item.alias)
        }
      })
    }
  }
  const subMenusToUpdate = [
    { submenu: listSportMenu.value?.slice(0, -1), type: 'sports' },
    { submenu: providerGames.value?.filter((game: IItemCongGame) => game.alias !== 'recently'), type: 'games' },
    { submenu: providerCasino.value?.filter((game: IItemCongGame) => game.alias !== 'all'), type: 'livecasino-type' }
  ]
  if (listSportMenu.value?.length) {
    const lastElement = listSportMenu.value[listSportMenu.value?.length - 1]
    const sportItem = menuList.find((item) => item.type === 'sports')
    if (sportItem) {
      sportItem.linkRecommend = PAGE_URLS.SPORT + '/' + lastElement.alias
    }
  }
  subMenusToUpdate.forEach(({ submenu, type }) => updateSubMenu(submenu, type))
})

const onClickGame = debounce((item, type) => {
  if (type === 'sports') {
    router.push(item.linkRecommend)
  } else {
    navigationCheckLoggedInAndOpenGame(item)
  }
}, 50)
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/header/index.scss"></style>
