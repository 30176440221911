<template>
  <div class="notification-list">
    <div
      v-for="(item, index) in notifications"
      :key="index"
      class="notification-list__item"
      @click="clickNotification(item)"
    >
      <div class="notification-list__item-title" v-html="item.title" />
      <p class="notification-list__item-time">{{ dayjs(item.created_at).format('hh:mm A DD/MM/YYYY') }}</p>
      <div class="notification-list__item-desc" v-html="item.description" />
    </div>
  </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import { useLogout } from '~/composables/user/useLogout'
import { useAppStore } from '~/store/app'
import { useNotification } from '~/composables/useNotification'
const { $pinia, $formatNumberWithCommas } = useNuxtApp()
const store = useAppStore($pinia)
const { logout } = useLogout()
const isShowMenu = ref(false)
const route = useRoute()
const router = useRouter()
const { notifications, fetchNotification } = useNotification()

const clickNotification = (item) => {
  router.push(item.path_alias)
}

onMounted(() => {
  fetchNotification()
})
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/header/notification-list.scss" />
