<template>
  <CommonBaseBModal
    :id="modalId"
    modal-class="modal-search"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
    @show="onShowModal"
    @hide="onHideModal"
  >
    <div class="modal-search__header">
      <span class="modal-search__header--title">Tìm kiếm</span>
      <span class="icon-close-circle-fill modal-search__header--close-btn" @click="closeModalSearch" />
    </div>
    <CommonBaseInput
      v-model="search"
      name="search"
      class="input-search"
      autocomplete="off"
      aria-autocomplete="none"
      placeholder="Nhập tên Game"
      :close="false"
      :limit-input="+$config.MAX_LENGTH_SEARCH_GAME"
      @update:model-value="onValueUpdate"
    >
      <span v-if="search" class="btn-clear" @click="clearSearch">Xóa</span>
    </CommonBaseInput>
    <ResultSearch
      v-if="isSearched"
      :is-loading="isLoading"
      :total-game="totalGame"
      :list-data="resultGames"
      :page="page"
      :keyword-search="textSearch"
      :suggested-keywords="suggestedKeywords"
      :on-press-keyword="selectedKeyword"
      @load-more="searchGame($event)"
    />
    <div v-else>
      <RecentlySearch
        v-show="recentlySearch.length"
        :list-data="recentlySearch"
        @selected-keyword="selectedKeyword"
      />
      <RecentlyGames v-show="recentlyGames.length" :list-data="recentlyGames" />
      <SuggestedKeywords
        :class="{ show: !isLoading && !resultGames.length }"
        :list-data="suggestedKeywords"
        :on-press="selectedKeyword"
      />
    </div>
  </CommonBaseBModal>
</template>
<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import SuggestedKeywords from './suggested-keyword/index.vue'
import ResultSearch from './result/index.vue'
import RecentlySearch from './recently-search/index.vue'
import RecentlyGames from './recently-game/index.vue'
import CommonBaseInput from '~/components/common/base-input'
import CommonBaseBModal from '~/components/common/base/BModal.vue'
import { MODAL } from '~/constants/modal'
import useModal from '~/composables/useModal'
import { useSearch } from '~/composables/search/useSearch'
import { useAppStore } from '~/store/app'

const { $config, $pinia } = useNuxtApp()

const { closeModal, closeAllModal } = useModal()
const modalId = ref(MODAL.SEARCH)
const isShowModal = ref(false)
const isSearched = ref(false)
const textSearch = ref('')

const {
  isLoading,
  search,
  recentlySearch,
  recentlyGames,
  suggestedKeywords,
  totalGame,
  page,
  resultGames,
  searchGame
} = useSearch()

const store = useAppStore($pinia)
const { currentUser } = storeToRefs(store)

const shouldTriggerSearch = ref(true)

const config: ModalConfig = {
  id: MODAL.SEARCH,
  centered: false,
  hideClose: true,
  fullscreen: false,
  iconClose: 'icon-close-circle-fill',
  noCloseOnBackdrop: true
}

watch(search, (value) => {
  if (!value) {
    clearSearch()
  }
})

watch(isShowModal, async (value) => {
  if (value) {
    page.value = 1
    await searchGame(null)
  }
})

watch(currentUser, (value) => {
  isShowModal.value = false
})

const onShowModal = () => {
  isShowModal.value = true
  page.value = 1

  if (!currentUser.value) {
    recentlySearch.value = []
    recentlyGames.value = []
  }
}

const onHideModal = () => {
  isShowModal.value = false
  isSearched.value = false
  page.value = 1
  search.value = ''
  recentlySearch.value = []
  resultGames.value = []
  isFocusSearchInput.value = false
}

const closeModalSearch = () => {
  closeAllModal()
  closeModal(modalId.value)
}

const selectedKeyword = (item) => {
  shouldTriggerSearch.value = false
  search.value = item
  page.value = 1
  handleSearchGame()
}

onUpdated(() => {
  if (isShowModal.value) {
    search.value = ''
    page.value = 1
    isSearched.value = false
    resultGames.value = []
  }
})

const onValueUpdate = debounce((value) => {
  if (shouldTriggerSearch.value) {
    handleSearchGame()
  } else {
    shouldTriggerSearch.value = true
  }
}, 1000)

const clearSearch = () => {
  search.value = ''
  page.value = 1
  isSearched.value = false
  resultGames.value = []
}

const handleSearchGame = () => {
  if (search.value.length > 1) {
    page.value = 1
    textSearch.value = search.value
    resultGames.value = []
    isSearched.value = true
    searchGame(null)
  }
}
</script>
<style lang="scss" scoped src="./index.scss" />
