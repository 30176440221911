import { useNotificationApi } from '~/api/notification'

export const useNotification = () => {
  const { $axios } = useNuxtApp()
  const { NOTIFICATION } = useNotificationApi()
  const notifications = ref([])

  const fetchNotification = async () => {
    try {
      const { data: response } = await $axios.get(NOTIFICATION)
      if (response?.status === 'OK' && response?.data?.items?.length > 0) {
        notifications.value = response?.data?.items
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return {
    notifications,
    fetchNotification
  }
}
